import React from 'react';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

class DatetimePicker extends React.Component {
  render() {
    const {
      classes,
      label,
      input: { value, onChange },
      required,
    } = this.props;
    return (
      <React.Fragment>
        <Typography style={{ color: 'rgba(0, 0, 0, 0.54)' }} variant="caption">
          {label}
          {required && ' *'}
        </Typography>
        <div className={classes.container}>
          <div className="picker">
            <KeyboardDateTimePicker
              clearable
              value={value || null}
              onChange={onChange}
              animateYearScrolling={false}
              format="MM/DD/YYYY hh:mm A"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '15px',
  },
};

export default withStyles(styles)(DatetimePicker);
