import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid } from '@material-ui/core';
import cx from 'classnames';

class Page extends React.Component {
  static defaultProps = {
    isLoading: false,
  };

  render() {
    const { classes, children, isLoading } = this.props;
    const [Header, Content] = children;
    return (
      <div className={classes.root}>
        {isLoading && (
          <div className={classes.loader}>
            <CircularProgress color="primary" size={60} />
          </div>
        )}
        {Header}
        <div className={cx(classes.content, isLoading && classes.hidden)}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              {Content}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing.unit,
  },
  hidden: {
    display: 'none',
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    height: '100%',
    width: '100vw',
    zIndex: 9999,
  },
});

export default withStyles(styles)(Page);
