import keyBy from 'lodash/keyBy';
import API from '../../utils/API';

const actions = {
  FETCH_ENTITIES: 'FETCH_ENTITIES',
  FETCH_ENTITY: 'FETCH_ENTITY',
  UPSERT_ENTITIES: 'UPSERT_ENTITIES',
  ADD_EVENT_PACKAGE: 'ADD_EVENT_PACKAGE',
  DELETE_ENTITY: 'DELETE_ENTITY',
};

const fetchEntities = (entityType, payload) => ({
  meta: { entityType },
  type: actions.FETCH_ENTITIES,
  payload,
});

const fetchEntity = (entityType, id, payload) => ({
  meta: { id, entityType },
  type: actions.FETCH_ENTITY,
  payload,
});

const deleteEntity = (entityType, id, payload) => ({
  meta: { id, entityType },
  type: actions.DELETE_ENTITY,
  payload,
});

export const fetchWebsites = () => fetchEntities('websites', API.getWebsites());

export const fetchHotels = () => fetchEntities('hotels', API.getHotels());

export const fetchLocations = () => fetchEntities('locations', API.getLocations());

export const deleteLocation = locationId =>
  deleteEntity('locations', locationId, API.deleteLocationById(locationId));

export const fetchCategories = websiteId =>
  fetchEntities('categories', API.getCategories(websiteId));

export const deleteCategory = categoryId =>
  deleteEntity('categories', categoryId, API.deleteCategoryById(categoryId));

export const fetchTestimonials = websiteId =>
  fetchEntities('testimonials', API.getTestimonials(websiteId));

export const fetchSponsors = websiteId => fetchEntities('sponsors', API.getSponsors(websiteId));

export const fetchTemplates = websiteId => fetchEntities('templates', API.getTemplates(websiteId));

export const fetchEvents = websiteId => fetchEntities('events', API.getEvents(websiteId));

export const fetchEventById = eventId => fetchEntity('events', eventId, API.getEventById(eventId));

export const deleteEvent = eventId => deleteEntity('events', eventId, API.deleteEventById(eventId));

export const deletePackage = packageId =>
  deleteEntity('packages', packageId, API.deletePackage(packageId));

export const fetchRegistrationById = registrationId =>
  fetchEntity('registrations', registrationId, API.getRegistrationById(registrationId));

export const upsertEntity = (entityType, entities) => {
  // Normalize to an array to handle both single and multiple entities
  entities = Array.isArray(entities) ? entities : [entities];
  return {
    meta: { entityType },
    type: actions.UPSERT_ENTITIES,
    payload: {
      [entityType]: keyBy(entities, 'id'),
    },
  };
};

export default actions;
