import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu as MenuIcon } from '@material-ui/icons';
import classNames from 'classnames';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { selectWebsiteId } from '../redux/app/actions';
import { fetchWebsites } from '../redux/entities/actions';
import { logout } from '../redux/user/actions';
import {
  AppBar,
  Button,
  CircularProgress,
  ErrorBoundary,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
  Typography,
  PageNotFound,
  Sidebar,
  withStyles,
} from '../components/';
import Login from './Login';

const drawerWidth = 215;
const Dashboard = React.lazy(() => import('./Dashboard'));
const Events = React.lazy(() => import('./Events'));
const Locations = React.lazy(() => import('./Locations'));
const Categories = React.lazy(() => import('./Categories'));
const Registrations = React.lazy(() => import('./Registrations'));
const EmailTemplates = React.lazy(() => import('./EmailTemplates'));
const Testimonials = React.lazy(() => import('./Testimonials'));
const Sponsors = React.lazy(() => import('./Sponsors'));
const Websites = React.lazy(() => import('./Websites'));
const Hotels = React.lazy(() => import('./Hotels'));

class App extends React.Component {
  state = {
    open: true,
  };

  handleDrawerOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleDrawerClose = () => {
    this.setState({
      open: false,
    });
  };

  handleLogout = () => {
    this.props.onLogout();
  };

  handleChangeWebsiteId = e => {
    const selectedWebsiteId = e.target.value;
    this.props.onSelectWebsiteId(selectedWebsiteId);
  };

  componentDidUpdate({
    selectedWebsiteId: previousSelectedWebsiteId,
    isLoggedIn: previousIsLoggedIn,
  }) {
    if (!previousIsLoggedIn && this.props.isLoggedIn) {
      this.props.fetchWebsites();
    }

    // Redirect back to dashboard when the website changes
    if (this.props.isLoggedIn && this.props.selectedWebsiteId !== previousSelectedWebsiteId) {
      this.props.history.replace(`/dashboard`);
    }
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.fetchWebsites();
    }
  }

  renderLoader = (size, withText) => {
    const { classes } = this.props;
    return (
      <div className={classes.rootLoader}>
        {withText && (
          <Typography className={classes.rootLoader__title} variant="h5" color="primary" noWrap>
            Loading Admin Panel
          </Typography>
        )}
        <CircularProgress color="primary" size={size} />
      </div>
    );
  };

  render() {
    const {
      classes,
      isLoggedIn,
      selectedWebsiteId,
      websites,
      websiteName,
      websiteLogo,
    } = this.props;
    if (!isLoggedIn) {
      return <Login />;
    } else if (!selectedWebsiteId) {
      return this.renderLoader(60, true);
    }
    return (
      <ErrorBoundary>
        <div className={classes.root}>
          <AppBar
            position="fixed"
            className={classNames(classes.appBar, {
              [classes.appBarShift]: this.state.open,
            })}
            elevation={1}
            color="primary">
            <Toolbar className={classes.appToolbar} disableGutters={true}>
              <div className={classes.appToolbar__left}>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.handleDrawerOpen}
                  className={classNames(classes.menuButton, {
                    [classes.hide]: this.state.open,
                  })}>
                  <MenuIcon />
                </IconButton>
                <div className={classes.select}>
                  <span className={classes.select__label}>Website</span>
                  <Select
                    className={classes.select__menu}
                    value={selectedWebsiteId}
                    onChange={this.handleChangeWebsiteId}>
                    {websites.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <Button
                className={classes.logout}
                color="inherit"
                aria-label="Logout"
                onClick={this.handleLogout}>
                Logout
              </Button>
            </Toolbar>
          </AppBar>
          <Sidebar
            onClose={this.handleDrawerClose}
            isOpen={this.state.open}
            websiteName={websiteName}
            websiteLogo={websiteLogo}
          />
          <main className={classes.content} key={selectedWebsiteId}>
            <div className={classes.toolbar} />
            {!selectedWebsiteId ? (
              <Typography variant="h6" color="inherit" noWrap>
                Select a website above!
              </Typography>
            ) : (
              <React.Suspense fallback={this.renderLoader(35, false)}>
                <Switch>
                  <Route path="/websites" component={Websites} />
                  <Route path="/sponsors" component={Sponsors} />
                  <Route path="/testimonials" component={Testimonials} />
                  <Route path="/categories" component={Categories} />
                  <Route path="/events" component={Events} />
                  <Route path="/locations" component={Locations} />
                  <Route path="/hotels" component={Hotels} />
                  <Route path="/registrations" component={Registrations} />
                  <Route path="/email-templates" component={EmailTemplates} />
                  <Route exact={true} path="/dashboard" component={Dashboard} />
                  <Route
                    exact={true}
                    path={['/', '/login']}
                    render={() => <Redirect to="/dashboard" />}
                  />
                  <Route component={PageNotFound} />
                </Switch>
              </React.Suspense>
            )}
          </main>
        </div>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = ({
  app: { selectedWebsiteId },
  entities: {
    websites: { data, isFetching: isFetchingWebsites, error: errorFetchingWebsites },
  },
  user: { isLoggedIn },
}) => ({
  isLoggedIn,
  selectedWebsiteId,
  websites: orderBy(data, 'name', 'desc'),
  isFetchingWebsites,
  errorFetchingWebsites,
  websiteName: get(data, [selectedWebsiteId, 'name']),
  websiteLogo: get(data, [selectedWebsiteId, 'logo']),
});

const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  rootLoader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  rootLoader__title: {
    marginBottom: theme.spacing.unit * 3,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 12,
  },
  hide: {
    display: 'none',
  },
  appToolbar: {
    justifyContent: 'space-between',
  },
  appToolbar__left: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  logout: {
    marginRight: theme.spacing.unit * 2,
  },
  select: {
    marginLeft: theme.spacing.unit * 2,
  },
  select__label: {
    marginRight: theme.spacing.unit * 2,
  },
  select__menu: {
    marginLeft: theme.spacing.unit * 2,
    minWidth: 300,
    color: theme.palette.primary.contrastText,
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    height: 38,
    paddingLeft: theme.spacing.unit * 2,
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      onLogout: logout,
      onSelectWebsiteId: selectWebsiteId,
      fetchWebsites,
    }
  )(withStyles(styles, { withTheme: true })(App))
);
