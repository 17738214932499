import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import { KeyboardBackspace as KeyboardBackIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

export default withRouter(
  withStyles(theme => ({
    header: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      flexGrow: 1,
    },
    inner: {
      display: 'inline-flex',
      alignItems: 'center',
      flexGrow: 1,
      marginBottom: theme.spacing.unit,
      flexWrap: 'wrap',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit * 2,
      height: 35,
    },
    subtitle: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing.unit * 2,
      height: 35,
    },
    back: {
      marginRight: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit,
    },
    back__icon: {
      marginRight: theme.spacing.unit,
    },
  }))(({ addBack, backHref, classes, children, history, subtitle, title, sameLine = true }) => (
    <header className={classes.header}>
      {addBack && (
        <Button
          className={classes.back}
          onClick={() => (backHref ? history.push(backHref) : history.goBack())}
          variant="text">
          <KeyboardBackIcon className={classes.back__icon} /> Back
        </Button>
      )}
      <div className={classes.inner}>
        {title && (
          <Typography className={classes.title} variant="h5" color="textPrimary" noWrap>
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography className={classes.subtitle} variant="h6" color="textSecondary" noWrap>
            {subtitle}
          </Typography>
        )}
        {sameLine && children}
      </div>
      {!sameLine && <div className={classes.inner}>{children}</div>}
    </header>
  ))
);
