const actions = {
  SELECT_WEBSITE: 'SELECT_WEBSITE',
};

export const selectWebsiteId = websiteId => ({
  type: actions.SELECT_WEBSITE,
  payload: {
    websiteId,
  },
});

export default actions;
