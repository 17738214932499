import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';

export default withRouter(({ history }) => (
  <div>
    <Button color="primary" onClick={history.goBack}>
      Back
    </Button>
    <br />
    <div style={{ padding: 10 }}>
      <Typography variant="h5" color="inherit" noWrap>
        Oops, page does not exist!
      </Typography>
    </div>
  </div>
));
