import actions from './actions';

const initialState = {
  eventIds: [],
  page: 0,
  pages: 1,
  sorted: [
    {
      id: 'eventStartDate',
      desc: false,
    },
  ],
  filtered: [],
  pageSize: 20,
};

const eventsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.UPDATE_TABLE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default eventsReducer;
