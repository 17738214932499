import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export default withStyles(theme => ({
  text: {
    padding: theme.spacing.unit * 2,
  },
}))(({ classes, text = 'No Data Found' }) => (
  <div className={classes.text}>
    <Typography variant="body1" color="textPrimary" noWrap>
      {text}
    </Typography>
  </div>
));
