import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reduxReset from 'redux-reset';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import promiseMiddleware from './middleware/promiseMiddleware';
import reducer from './reducers';

const persistConfig = {
  key: '@bmorearoundtown-web-admin',
  storage,
  whitelist: ['app', 'user'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const middleware = [thunk, promiseMiddleware()];

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

export default () => {
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middleware), reduxReset())
  );
  return {
    store,
    persistor: persistStore(store),
  };
};
