import React from 'react';
import Editor from '../Editor';

export default ({ editorClassName, input: { name, onChange, value } }) => (
  <Editor
    className={editorClassName}
    mode="html"
    theme="github"
    height="100%"
    width="100%"
    onChange={onChange}
    name="html-template-editor"
    editorProps={{ $blockScrolling: false }}
    value={value}
    setOptions={{
      showLineNumbers: true,
      tabSize: 2,
    }}
  />
);
