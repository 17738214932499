const actions = {
  UPDATE_TABLE: 'UPDATE_TABLE',
};

export const updateEventsTable = ({
  eventIds,
  pageSize,
  page,
  pages,
  sorted,
  filtered,
  isLoading,
}) => ({
  type: 'UPDATE_TABLE',
  payload: {
    pageSize,
    page,
    pages,
    sorted,
    filtered,
    eventIds,
    isLoading,
  },
});

export default actions;
