import axios from 'axios';
import { subMonths } from 'date-fns';
import jwtDecode from 'jwt-decode';
import get from 'lodash/get';
import { logout as userLogout } from '../redux/user/actions';
import { store } from '../index';

class API {
  constructor(url) {
    this.url = url;
  }

  async fetch({ all, ...options }) {
    try {
      const token = store.getState().user.token;
      const res = await axios({
        ...options,
        headers: {
          ...(options.headers || {}),
          'X-Access-Token': token,
        },
      });
      return all ? res.data : res.data.data;
    } catch (error) {
      if (error.response.status === 403 || error.response.status === 422) {
        store.dispatch(userLogout());
      }
      throw error.response.data.error;
    }
  }

  async login(email, password) {
    try {
      const res = await axios({
        method: 'POST',
        url: `${this.url}/admin/auth`,
        data: {
          email,
          password,
        },
      });
      const data = get(res, 'data.data');
      const jwt = get(data, 'token');
      const { user } = jwtDecode(jwt);
      return {
        ...data,
        defaultWebsiteId: user.defaultWebsiteId,
      };
    } catch (err) {
      throw get(err, 'response.data.error', 'Server Error Occurred');
    }
  }

  logout() {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/logout`,
    });
  }

  createWebsite(data) {
    return this.fetch({
      method: 'POST',
      url: `${this.url}/websites`,
      data,
    });
  }

  editWebsite(id, data) {
    return this.fetch({
      method: 'PUT',
      url: `${this.url}/websites/${id}`,
      data,
    });
  }

  getWebsites() {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/websites`,
    });
  }

  createHotel(data) {
    return this.fetch({
      method: 'POST',
      url: `${this.url}/hotels`,
      data,
    });
  }

  editHotel(id, data) {
    return this.fetch({
      method: 'PUT',
      url: `${this.url}/hotels/${id}`,
      data,
    });
  }

  getHotels() {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/hotels`,
    });
  }

  downloadRegistrationLink(websiteId, terms) {
    return this.getRegistrations(websiteId, 0, 1000000, terms, true);
  }

  getRegistrations(websiteId, offset, limit, { search, events }, asCsv = false) {
    let searchExp;
    if (search) {
      const keywords = search.split(' ');
      const orExp = keywords.reduce((acc, k) => {
        const lowerK = k.toLowerCase();
        acc = acc.concat([
          {
            firstName: {
              $like: `%${lowerK}%`,
            },
          },
          {
            lastName: {
              $like: `%${lowerK}%`,
            },
          },
          {
            emailAddress: {
              $like: `%${lowerK}%`,
            },
          },
          {
            paypalEmailAddress: {
              $like: `%${lowerK}%`,
            },
          },
          {
            phoneNumber: {
              $like: `%${lowerK}%`,
            },
          },
          {
            confirmationNumber: {
              $eq: `%${lowerK}%`,
            },
          },
          {
            ticketId: {
              $eq: `%${lowerK}%`,
            },
          },
        ]);
        return acc;
      }, []);
      searchExp = {
        $or: orExp,
      };
    }

    const params = {
      websiteId,
      limit,
      offset,
      order: JSON.stringify([['datePaid', 'desc']]),
      where: JSON.stringify({
        ...(searchExp ? searchExp : {}),
      }),
      ...(events ? { events: JSON.stringify(events) } : {}),
    };

    if (asCsv) {
      const url = new URL(`${this.url}/registrations/export`);
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
      return url;
    }
    return this.fetch({
      all: true,
      method: 'GET',
      url: `${this.url}/registrations`,
      params,
    });
  }

  getRecentlyCompletedRegistrations(websiteId) {
    const oneMonthAgo = subMonths(new Date(), 1);
    return this.fetch({
      method: 'GET',
      url: `${this.url}/registrations`,
      params: {
        websiteId,
        limit: 25,
        order: JSON.stringify([['datePaid', 'desc']]),
        datePaid: {
          $gt: oneMonthAgo,
        },
      },
    });
  }

  getEventRegistrations(eventId) {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/registrations`,
      params: {
        events: JSON.stringify([eventId]),
        limit: 5000,
        order: JSON.stringify([['lastName', 'asc']]),
        where: {
          ipnResponse: 'Completed',
        },
      },
    });
  }

  createRegistration(data) {
    return this.fetch({
      method: 'POST',
      url: `${this.url}/registrations`,
      data,
    });
  }

  editRegistration(id, data) {
    return this.fetch({
      method: 'PUT',
      url: `${this.url}/registrations/${id}`,
      data,
    });
  }

  getRegistrationById(registrationId) {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/registrations/${registrationId}`,
    });
  }

  resendEmailConfirmation(id) {
    return this.fetch({
      method: 'POST',
      url: `${this.url}/registrations/resend-email`,
      params: {
        id,
        emailType: 'confirmation',
      },
    });
  }

  getCurrentAndUpcomingEvents(websiteId) {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/events`,
      params: {
        websiteId,
        limit: 10,
        order: JSON.stringify([['eventStartDate', 'asc']]),
        where: JSON.stringify({
          isActive: true,
          isPublished: true,
          endDate: {
            $gte: new Date(),
          },
          eventStartDate: {
            $gte: new Date(),
          },
        }),
        include: JSON.stringify(['Registration']),
      },
    });
  }

  getEvents(websiteId, { order, where, other, asSelectList, all = true, include, ...params }) {
    return this.fetch({
      all,
      method: 'GET',
      url: `${this.url}/events`,
      params: {
        asSelectList,
        websiteId,
        ...params,
        ...(order ? { order: JSON.stringify(order) } : {}),
        ...(where ? { where: JSON.stringify(where) } : {}),
        ...(other ? { other: JSON.stringify(other) } : {}),
        ...(include ? { include: JSON.stringify(include) } : {}),
      },
    });
  }

  getEventById(id) {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/events/${id}`,
      params: {
        include: JSON.stringify(['Registration']),
      },
    });
  }

  createEvent(data) {
    return this.fetch({
      method: 'POST',
      url: `${this.url}/events`,
      data,
    });
  }

  uploadImage(file) {
    const formData = new FormData();
    formData.append('image', file);
    return this.fetch({
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
      },
      url: `${this.url}/events/image-upload`,
      data: formData,
    });
  }

  editEvent(id, data) {
    return this.fetch({
      method: 'PUT',
      url: `${this.url}/events/${id}`,
      data,
    });
  }

  deleteEventById(id) {
    return this.fetch({
      method: 'DELETE',
      url: `${this.url}/events/${id}`,
    });
  }

  getLocations() {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/locations`,
      params: {
        limit: 1000,
      },
    });
  }

  getLocationById(id) {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/locations/${id}`,
    });
  }

  createLocation(data) {
    return this.fetch({
      method: 'POST',
      url: `${this.url}/locations`,
      data,
    });
  }

  editLocation(id, data) {
    return this.fetch({
      method: 'PUT',
      url: `${this.url}/locations/${id}`,
      data,
    });
  }

  updateLocationById(id, data) {
    return this.fetch({
      method: 'PUT',
      url: `${this.url}/locations/${id}`,
      data,
    });
  }

  deleteLocationById(id) {
    return this.fetch({
      method: 'DELETE',
      url: `${this.url}/locations/${id}`,
    });
  }

  getTestimonials(websiteId) {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/testimonials`,
      params: {
        websiteId,
      },
    });
  }

  createTestimonial(data) {
    return this.fetch({
      method: 'POST',
      url: `${this.url}/testimonials`,
      data,
    });
  }

  editTestimonial(id, data) {
    return this.fetch({
      method: 'PUT',
      url: `${this.url}/testimonials/${id}`,
      data,
    });
  }

  getSponsors(websiteId) {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/sponsors`,
      params: {
        websiteId,
      },
    });
  }

  createSponsor(data) {
    return this.fetch({
      method: 'POST',
      url: `${this.url}/sponsors`,
      data,
    });
  }

  editSponsor(id, data) {
    return this.fetch({
      method: 'PUT',
      url: `${this.url}/sponsors/${id}`,
      data,
    });
  }

  deleteSponsorById(id) {
    return this.fetch({
      method: 'DELETE',
      url: `${this.url}/sponsors/${id}`,
    });
  }

  getCategories(websiteId) {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/categories`,
      params: {
        websiteId,
      },
    });
  }

  getCategoryById(id) {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/categories/${id}`,
    });
  }

  createCategory(data) {
    return this.fetch({
      method: 'POST',
      url: `${this.url}/categories`,
      data,
    });
  }

  editCategory(id, data) {
    return this.fetch({
      method: 'PUT',
      url: `${this.url}/categories/${id}`,
      data,
    });
  }

  deleteCategoryById(id) {
    return this.fetch({
      method: 'DELETE',
      url: `${this.url}/categories/${id}`,
    });
  }

  getTemplates(websiteId) {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/templates`,
      params: {
        websiteId,
      },
    });
  }

  getTemplateById(id) {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/templates/${id}`,
    });
  }

  createTemplate(data) {
    return this.fetch({
      method: 'POST',
      url: `${this.url}/templates`,
      data,
    });
  }

  editTemplate(id, data) {
    return this.fetch({
      method: 'PUT',
      url: `${this.url}/templates/${id}`,
      data,
    });
  }

  deleteTemplateById(id) {
    return this.fetch({
      method: 'DELETE',
      url: `${this.url}/templates/${id}`,
    });
  }

  getPackages(websiteId) {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/packages?limit=10000`,
      params: {
        websiteId,
      },
    });
  }

  getPackagesByEventId(eventId) {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/packages?limit=10000`,
      params: {
        eventId,
      },
    });
  }

  getPackage(id) {
    return this.fetch({
      method: 'GET',
      url: `${this.url}/packages/${id}`,
    });
  }

  createPackage(data) {
    return this.fetch({
      method: 'POST',
      url: `${this.url}/packages`,
      data,
    });
  }

  editPackage(id, data) {
    return this.fetch({
      method: 'PUT',
      url: `${this.url}/packages/${id}`,
      data,
    });
  }

  deletePackage(id) {
    return this.fetch({
      method: 'DELETE',
      url: `${this.url}/packages/${id}`,
    });
  }
}

export default new API(process.env.REACT_APP_API || window.location.origin);
