import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Drawer,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Category as CategoryIcon,
  ChevronLeft,
  Comment as TestimonialIcon,
  Event as EventIcon,
  EventSeat as RegistrationIcon,
  Email as EmailIcon,
  Dashboard as DashboardIcon,
  MoneyOff as DiscountIcon,
  Hotel as HotelIcon,
  LocationOn as LocationOnIcon,
  Web as WebsiteIcon,
  AccountCircle as AccountIcon,
  AttachMoney as SponsorIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import SiteLink from './SiteLink';

const drawerWidth = 215;

const SidebarItem = withStyles(theme => ({
  typography: {
    color: theme.palette.secondary.contrastText,
    fontSize: '0.95rem',
  },
  listIcon: {
    marginRight: 0,
  },
  icon: {
    color: theme.palette.secondary.contrastText,
  },
}))(({ classes, icon: Icon, title, to }) => (
  <ListItem className={classes.typography} button component={SiteLink} to={to}>
    <Tooltip title={title} placement="right-start">
      <ListItemIcon className={classes.listIcon}>
        <Icon className={classes.icon} />
      </ListItemIcon>
    </Tooltip>
    <ListItemText
      primary={title}
      primaryTypographyProps={{
        className: classes.typography,
      }}
    />
  </ListItem>
));

const Sidebar = ({ classes, onClose, isOpen, websiteName, websiteLogo }) => (
  <Drawer
    variant="permanent"
    className={classNames(classes.drawer, {
      [classes.drawerOpen]: isOpen,
      [classes.drawerClose]: !isOpen,
    })}
    classes={{
      paper: classNames({
        [classes.drawerOpen]: isOpen,
        [classes.drawerClose]: !isOpen,
      }),
    }}
    open={isOpen}
    PaperProps={{
      classes: {
        root: classes.drawer__root,
      },
    }}>
    <div className={classes.toolbar}>
      {websiteLogo ? (
        <img src={websiteLogo} style={{ height: 40, width: 50 }} alt="Company Logo" />
      ) : (
        <Typography className={classes.typography} variant="h6">
          Admin Portal
        </Typography>
      )}
      <IconButton className={classes.typography} onClick={onClose}>
        <ChevronLeft color="inherit" />
      </IconButton>
    </div>
    <Divider className={classes.divider} />
    <Typography
      style={{ display: isOpen ? 'block' : 'none' }}
      className={classNames(classes.dividerFullWidth, classes.typography)}
      variant="body2">
      {websiteName || 'Site'}
    </Typography>
    <List>
      <SidebarItem icon={DashboardIcon} to="/" title="Dashboard" />
      <SidebarItem icon={EventIcon} to="/events" title="Events" />
      <SidebarItem icon={CategoryIcon} to="/categories" title="Categories" />
      <SidebarItem icon={RegistrationIcon} to="/registrations" title="Registrations" />
      <SidebarItem icon={EmailIcon} to="/email-templates" title="Email Templates" />
      <SidebarItem icon={TestimonialIcon} to="/testimonials" title="Testimonials" />
      <SidebarItem icon={LocationOnIcon} to="/locations" title="Locations" />
      <SidebarItem icon={HotelIcon} to="/hotels" title="Hotels" />
      {/*<SidebarItem icon={DiscountIcon} to="/discounts" title="Discounts" />*/}
      {/*<SidebarItem icon={AccountIcon} to="/accounts" title="Accounts" />*/}
      {/*<SidebarItem icon={SponsorIcon} to="/sponsors" title="Sponsors" />*/}
      <Divider className={classes.divider} />
      <Typography
        style={{ display: isOpen ? 'block' : 'none' }}
        className={classNames(classes.dividerFullWidth, classes.typography)}
        variant="caption">
        Global Manage
      </Typography>
      <SidebarItem icon={WebsiteIcon} to="/websites" title="Websites" />
    </List>
  </Drawer>
);

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawer__root: {
    backgroundColor: theme.palette.secondary.main,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
  },
  toolbar: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit,
    ...theme.mixins.toolbar,
  },
  typography: {
    color: theme.palette.secondary.contrastText,
    fontSize: '0.9rem',
  },
  divider: {
    backgroundColor: theme.palette.secondary.light,
  },
  dividerFullWidth: {
    margin: `${theme.spacing.unit * 2}px 0 0 ${theme.spacing.unit * 2}px`,
  },
});

export default withStyles(styles)(Sidebar);
