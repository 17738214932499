import React from 'react';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

class SelectField extends React.Component {
  componentDidMount() {
    if (this.props.onLoad) {
      this.props.onLoad();
    }
  }

  render() {
    const {
      input: { onChange, value, ...restInput },
      label,
      options,
      style,
      required,
    } = this.props;
    return (
      <React.Fragment>
        <Typography style={{ color: 'rgba(0, 0, 0, 0.54)' }} variant="caption">
          {label}
          {required && ' *'}
        </Typography>
        <Select value={value} onChange={onChange} inputProps={restInput} style={style}>
          <MenuItem key="no-option-selected" value="">
            <em>None</em>
          </MenuItem>
          {options &&
            options.length > 0 &&
            options.map(({ value, display }) => (
              <MenuItem key={value} value={value}>
                {display}
              </MenuItem>
            ))}
        </Select>
      </React.Fragment>
    );
  }
}
export default SelectField;
