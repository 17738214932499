import actions from './actions';

const initialState = {
  email: null,
  token: null,
  isLoggingIn: false,
  isLoggedIn: false,
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.USER_CLEAR_LOGIN:
      return {
        ...state,
        error: null,
        isLoggingIn: false,
      };

    case `${actions.USER_LOGIN}_START`:
      return {
        ...state,
        error: null,
        isLoggingIn: true,
      };

    case `${actions.USER_LOGIN}_SUCCESS`:
      const { email, token } = payload;
      return {
        ...state,
        email,
        token,
        isLoggedIn: true,
        isLoggingIn: false,
      };

    case `${actions.USER_LOGIN}_ERROR`:
      console.log('payload', payload);
      return {
        ...state,
        error: payload,
        isLoggedIn: false,
        isLoggingIn: false,
      };

    case actions.USER_LOGOUT:
      return {
        ...state,
        email: null,
        isLoggedIn: false,
      };

    default:
      return state;
  }
};

export default userReducer;
