import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { Email as EmailIcon } from '@material-ui/icons';
import isEmpty from 'lodash/isEmpty';
import SiteLink from './SiteLink';

const formatter = 'MMM Do, YYYY, hh:mm A';

const RegistrationCard = ({ classes, onEmail, ...registration }) => {
  const {
    amountPaid,
    firstName,
    lastName,
    datePaid,
    emailAddress,
    paypalEmailAddress,
    confirmationNumber,
    phoneNumber,
    registrationPackages = [],
    ipnResponse,
  } = registration;

  const name = `${firstName} ${lastName}`;

  const wasRefunded = ipnResponse === 'Refunded' || ipnResponse === 'Canceled_Reversal';

  return (
    <Card className={classes.root} raised>
      <CardHeader
        className={classes.header}
        action={
          ipnResponse === 'Completed' && (
            <Tooltip disableFocusListener title="Resend Email Confirmation">
              <IconButton aria-label="Resend email confirmation" onClick={onEmail}>
                <EmailIcon />
              </IconButton>
            </Tooltip>
          )
        }
        title={name}
        subheader={format(datePaid, formatter)}
      />
      <CardContent>
        <Grid container spacing={12}>
          <Grid item xs={4}>
            <Typography paragraph variant="body2">
              <strong>Status:</strong>
              <Typography variant="body2">{ipnResponse || 'N/A'}</Typography>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography paragraph variant="body2">
              <strong>{wasRefunded ? 'Refunded:' : 'Paid:'}</strong>
              <Typography variant="body2">
                {wasRefunded ? '-' : null}${parseFloat(amountPaid).toFixed(2)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography paragraph variant="body2">
              <strong>Confirmation:</strong>
              <Typography variant="body2">{confirmationNumber}</Typography>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography paragraph variant="body2">
              <strong>Email:</strong>
              <Typography variant="body2">{emailAddress || paypalEmailAddress || 'N/A'}</Typography>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography paragraph variant="body2">
              <strong>Phone:</strong>
              <Typography variant="body2">{phoneNumber}</Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>Package(s):</strong>
            </Typography>
            {!isEmpty(registrationPackages) && registrationPackages.map((registeredPackage, index) => {
              const {
                eventId,
                event,
                name,
                details,
              } = registeredPackage;
              if(!event){
                return null
              }
              return (
                <Typography key={index} variant="body2" color="textSecondary">
                  <SiteLink to={`/events/${eventId}?tabIndex=1`}>
                    {event ? event.name : 'Deleted Event'}: {name}{' '}
                  </SiteLink>
                  <span>- {details.numberOfTickets} ticket(s)</span>
                </Typography>
              );
            })}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const styles = theme => ({
  root: {
    maxWidth: '100%',
  },
  header: {
    padding: '16px 16px 0 16px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: grey[500],
  },
});

export default withStyles(styles, { withTheme: true })(RegistrationCard);
