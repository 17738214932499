import React from 'react';
import debounce from 'lodash/debounce';
import { Typography } from '@material-ui/core';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

class GoogleMap extends React.Component {
  cache = {};

  constructor(props) {
    super(props);
    this.state = {
      isInit: true,
      updatingMap: false,
      lat: props.initialCoords ? props.initialCoords.lat : 0,
      lng: props.initialCoords ? props.initialCoords.lng : 0,
    };
  }

  addressToCoords = debounce(async address => {
    if (!address) {
      return;
    }

    // Use local cache if its been looked up before
    if (this.cache[address]) {
      this.setState(this.cache[address]);
    } else {
      if (window.google && window.google.maps) {
        const api = new window.google.maps.Geocoder();
        this.setState({ updatingMap: true }, () => {
          api.geocode({ address }, (results, status) => {
            if (status === 'OK') {
              const location = results[0].geometry.location;
              const coords = {
                lat: location.lat(),
                lng: location.lng(),
              };
              this.setState({
                ...coords,
                updatingMap: false,
              });
              this.cache[address] = coords;
            } else {
              this.setState({
                updatingMap: false,
              });
            }
          });
        });
      }
    }
  }, 3000);

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isInit && this.state.isInit) {
      this.addressToCoords(this.props.address);
    } else if (this.state.isInit && prevProps.address !== this.props.address) {
      this.addressToCoords(this.props.address);
    }
  }

  componentDidMount() {
    if (this.state.isInit) {
      this.addressToCoords(this.props.address);
    }
  }

  render() {
    const { height, width, address, google } = this.props;
    const { lat, lng, updatingMap } = this.state;
    const coords = {
      lat,
      lng,
    };

    if (!address) {
      return (
        <Typography variant="h5" color="inherit" noWrap>
          No Location Found!
        </Typography>
      );
    }

    return updatingMap ? (
      <div>
        <Typography variant="h5" color="inherit" noWrap>
          Retrieving latitude and longitude
        </Typography>
        <Typography variant="body1" color="inherit" noWrap>
          {address}
        </Typography>
      </div>
    ) : (
      <React.Fragment>
        <p>
          lat: {this.state.lat}, lng: {this.state.lng}
        </p>
        <Map
          key={coords}
          google={google}
          style={{
            height,
            width,
          }}
          zoom={16}
          initialCenter={coords}>
          <Marker title="Location" position={coords} />
        </Map>
      </React.Fragment>
    );
  }
}

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export default GoogleApiWrapper({
  apiKey: API_KEY,
})(GoogleMap);
