import { persistor } from '../../index';
import API from '../../utils/API';

const actions = {
  USER_LOGIN: 'USER_LOGIN',
  USER_CLEAR_LOGIN: 'USER_CLEAR_LOGIN',
  USER_LOGOUT: 'USER_LOGOUT',
};

export const clearLogin = () => ({
  type: actions.USER_CLEAR_LOGIN,
});

export const login = (email, password) => ({
  type: actions.USER_LOGIN,
  payload: API.login(email, password),
});

export const logout = () => async dispatch => {
  await API.logout();
  await dispatch({ type: actions.USER_LOGOUT });
  persistor.purge();
  dispatch({ type: 'RESET' });
};

export default actions;
