import actions from './actions';
import get from 'lodash/get';
import find from 'lodash/find';
import userActions from '../user/actions';
import entityActions from '../entities/actions';

const initialState = {
  selectedWebsiteId: null,
};

const appReducer = (state = initialState, action) => {
  const { type, payload, meta } = action;
  switch (type) {
    case actions.SELECT_WEBSITE:
      return {
        ...state,
        selectedWebsiteId: payload.websiteId,
      };

    // Set selected to default website id
    case `${userActions.USER_LOGIN}_SUCCESS`:
      const { defaultWebsiteId = '' } = payload;
      return {
        ...state,
        selectedWebsiteId: defaultWebsiteId,
      };

    // Set initial selected website
    case `${entityActions.FETCH_ENTITIES}_SUCCESS`:
      if (meta.entityType === 'websites') {
        const foundId = find(payload, { id: state.selectedWebsiteId });
        return {
          ...state,
          selectedWebsiteId: foundId ? foundId.id : get(payload, [0, 'id']),
        };
      }
      return state;

    default:
      return state;
  }
};

export default appReducer;
