import { combineReducers } from 'redux';

import appReducer from './app/reducer';
import entitiesReducer from './entities/reducer';
import eventsReducer from './events/reducer';
import userReducer from './user/reducer';

export default combineReducers({
  app: appReducer,
  entities: entitiesReducer,
  events: eventsReducer,
  user: userReducer,
});
