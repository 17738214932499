import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const SiteLink = ({ classes, ...rest }) => <Link className={classes.link} {...rest} />;

const styles = theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.link,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export default withStyles(styles)(SiteLink);
