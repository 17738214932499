import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { AccountCircle, Lock } from '@material-ui/icons';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import capitalize from 'lodash/capitalize';
import { withRouter } from 'react-router-dom';
import { clearLogin, login } from '../redux/user/actions';
import { Button, CircularProgress, InputAdornment } from '../components';
import { version } from '../../package.json';

class Login extends React.Component {
  state = {
    email: '',
    password: '',
  };

  handleLogin = e => {
    e.preventDefault();
    const { email, password } = this.state;
    this.props.onLogin(email, password);
  };

  handleError = error => {
    this.setState({ error });
  };

  handleInputChange = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  componentDidMount() {
    this.props.onClearLogin();
    this.props.history.replace('/login');
  }

  componentDidUpdate({ token }) {
    // Logged in - forward to dashboard
    if (this.props.token && this.props.token !== token) {
      this.props.history.replace('/');
    }
  }

  render() {
    const { classes, error, isLoggingIn } = this.props;
    const { email, password } = this.state;
    const hasError = !!error;

    return (
      <div className={classes.container}>
        <div className={classes.overlay} />
        <img
          src={require('../assets/images/login.jpg')}
          className={classes.image}
          alt="login background"
        />
        <form className={classes.form} onSubmit={this.handleLogin}>
          <h1 className={classes.form__title}>
            <strong className={classes.bmore}>BMOREAroundTown</strong>{' '}
            <span className={classes.title__sub}>Admin</span>
          </h1>
          {hasError && <p className={classes.form__error}>{capitalize(error)}</p>}
          <fieldset className={classes.form__fieldset} disabled={isLoggingIn}>
            <TextField
              className={classes.form__input}
              required
              id="user-email"
              autoComplete="user-email"
              label="Email"
              margin="normal"
              variant="outlined"
              value={email}
              onChange={this.handleInputChange.bind(this, 'email')}
              error={hasError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle color={hasError ? 'error' : 'primary'} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.form__input}
              required
              id="user-password"
              autoComplete="user-password"
              label="Password"
              margin="normal"
              variant="outlined"
              type="password"
              value={password}
              onChange={this.handleInputChange.bind(this, 'password')}
              error={hasError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock color={hasError ? 'error' : 'primary'} />
                  </InputAdornment>
                ),
              }}
            />
          </fieldset>
          <div className={classes.submit}>
            {isLoggingIn ? (
              <CircularProgress className={classes.progress} />
            ) : (
              <Button type="submit" className={classes.button} color="primary" variant="outlined">
                Login
              </Button>
            )}
          </div>
          <span className={classes.version}>v{version}</span>
        </form>
      </div>
    );
  }
}

const styles = theme => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'transparent',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
    },
    overlay: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1,
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    image: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 0,
      filter: 'grayscale(100%)',
    },
    form: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      width: 500,
      maxWidth: '75vw',
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      zIndex: 2,
      backgroundColor: 'rgba(255, 255, 255, 1)',
      padding: theme.spacing.unit * 8,
      border: '1px solid #000',
      ...theme.shape,
    },
    form__fieldset: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      flexGrow: 1,
      border: 'none',
      padding: 0,
      margin: 0,
    },
    form__input: {
      display: 'flex',
      width: '100%',
    },
    form__error: {
      ...theme.typography.body,
      color: theme.palette.error.main,
    },
    form__title: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      ...theme.typography.body,
      fontWeight: 400,
      marginTop: 0,
      color: theme.palette.primary.main,
      fontSize: 22,
    },
    title__sub: {
      color: '#aaa',
      fontSize: 15,
      marginTop: 6,
      textTransform: 'uppercase',
      fontWeight: 500,
    },
    button: {
      marginTop: theme.spacing.unit * 2,
      height: 56,
      width: '100%',
    },
    progress: {
      margin: theme.spacing.unit * 2,
    },
    submit: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    version: {
      position: 'absolute',
      right: 70,
      bottom: 20,
      fontSize: 12,
      color: '#555',
    },
    bmore: {
      color: '#241773',
    },
  };
};

export default withRouter(
  connect(
    ({ user }) => ({ ...user }),
    {
      onLogin: login,
      onClearLogin: clearLogin,
    }
  )(withStyles(styles)(Login))
);
