import React from 'react';
import ReactTable from 'react-table';
import cx from 'classnames';

const tableElementProps = ({ rowHeight = 50 }) => ({
  /*
  getTableProps: () => ({}),
  getTheadGroupProps: () => ({}),
  getTheadGroupTrProps: () => ({}),
  getTheadGroupThProps: () => ({}),
  */
  getTheadProps: props => {
    return {
      // ...props,
      style: {
        ...props.style,
        backgroundColor: 'inherit',
        border: 'none',
        height: rowHeight,
        boxShadow: props.filterable ? 'none' : props.style.boxShadow,
      },
    };
  },
  getTheadThProps: props => ({
    //...props,
    style: {
      ...props.style,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 10,
      border: 'none',
      fontSize: '0.9rem',
      color: 'rgba(0,0,0, 0.54)',
      backgroundColor: 'rgba(0,0,0,0.03)',
      height: rowHeight,
      fontWeight: 600,
    },
  }),
  getTheadFilterProps: props => ({
    //...props,
    style: {
      ...props.style,
      border: 'none',
      backgroundColor: 'rgba(0,0,0,0.03)',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.15)',
      height: rowHeight + 10,
      paddingTop: 10,
    },
  }),
  getTheadFilterTrProps: props => ({
    //...props,
    style: {
      ...props.style,
      height: rowHeight,
    },
  }),
  getTheadFilterThProps: props => ({
    //...props,
    style: {
      ...props.style,
    },
  }),
  /*
  getTbodyProps: () => ({}),
  getTrGroupProps: () => ({}),
  */
  getTrProps: () => ({
    style: {
      height: rowHeight,
      border: 'none',
    },
  }),
  getTdProps: () => ({
    style: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 10,
      border: 'none',
      fontSize: '0.9rem',
    },
  }),
  /*
  getTfootProps: () => ({}),
  getTfootTrProps: () => ({}),
  getTfootThProps: () => ({}),
  */
  getPaginationProps: props => ({
    // ...props,
    style: {
      ...props.style,
      border: 'none',
      backgroundColor: 'rgba(0,0,0,0.03)',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.15)',
      height: rowHeight,
    },
  }),
  /*
  getLoadingProps: () => ({}),
  getNoDataProps: () => ({}),
  getResizerProps: () => ({}),
  */
});

export default props => (
  <ReactTable
    className={cx(props.className, '-striped -highlight')}
    {...tableElementProps(props)}
    sortable={true}
    filterable={true}
    resizeable={true}
    {...props}
  />
);
