import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import FormLabel from '@material-ui/core/FormLabel';
import { ChromePicker as ColorPicker } from 'react-color';

export default withStyles(theme => ({
  picker: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
}))(({ classes, input: { name, onChange, value, ...restInput }, meta, label }) => (
  <React.Fragment>
    <FormLabel>{label}</FormLabel>
    <ColorPicker className={classes.picker} onChange={onChange} color={value} />
  </React.Fragment>
));
