import React from 'react';
import { CircularProgress, Divider, Card, CardContent, CardHeader } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const Panel = ({
  icon,
  actions,
  classes,
  children,
  title,
  isLoading,
  noPadding,
  removeHeader = false,
  raised = false,
}) => (
  <Card className={classes.panel} raised={raised}>
    {isLoading && (
      <div className={classes.loader}>
        <CircularProgress color="primary" size={30} />
      </div>
    )}
    {!removeHeader && (
      <CardHeader
        avatar={icon}
        title={title}
        titleTypographyProps={{
          color: 'primary',
          variant: 'body1',
          style: {
            fontWeight: 600,
            textTransform: 'uppercase',
          },
        }}
        action={actions}
      />
    )}
    <Divider />
    <CardContent
      style={{
        padding: noPadding ? 0 : 15,
      }}>
      {children}
    </CardContent>
  </Card>
);

const styles = () => ({
  panel: {
    position: 'relative',
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    height: '100%',
    width: '100%',
    zIndex: 3,
  },
});

export default withStyles(styles)(Panel);
