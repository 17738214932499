import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import API from '../../utils/API';

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      file: {
        preview: props.input.value,
      },
    };
  }

  onDrop([file]) {
    try {
      this.setState(
        {
          isUploading: true,
          error: null,
        },
        async () => {
          const { imageUrl } = await API.uploadImage(file);
          this.setState(
            {
              ...file,
              preview: window.URL.createObjectURL(file),
              isUploading: false,
            },
            () => {
              this.props.input.onChange(imageUrl);
            }
          );
        }
      );
    } catch (error) {
      this.setState({
        error,
        isUploading: false,
      });
    }
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    if (this.state.file && this.state.file.preview) {
      window.URL.revokeObjectURL(this.state.file.preview);
    }
  }

  render() {
    const {
      classes,
      label,
      input: { value },
      required,
    } = this.props;
    const { error, file, isUploading } = this.state;
    return (
      <React.Fragment>
        <Typography style={{ color: 'rgba(0, 0, 0, 0.54)' }} variant="caption">
          {label}
          {required && ' *'}
        </Typography>
        <Dropzone accept="image/*" onDrop={this.onDrop.bind(this)}>
          {({ getRootProps, getInputProps }) => (
            <div className={classes.root} {...getRootProps()}>
              <input {...getInputProps()} />
              <Typography className={classes.help} variant="body1">
                {isUploading ? 'Uploading image to cloud....' : 'Drop or select an image'}
              </Typography>
              <div className={classes.thumbs}>
                <div className={classes.thumb} key={file.name}>
                  <div className={classes.thumb__inner}>
                    {value && (
                      <div>
                        <p>Large</p>
                        <img src={value} className={classes.image__lg} alt="large preview file" />
                      </div>
                    )}
                    {value && (
                      <div>
                        <p>Small</p>
                        <img src={value} className={classes.image__sm} alt="small preview file" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
        {error && <h3>Uh oh! There was an error attempting to upload the file to the cloud.</h3>}
      </React.Fragment>
    );
  }
}

const styles = {
  root: {
    cursor: 'pointer',
  },
  thumbs: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  },
  thumb: {
    display: 'inline-flex',
    border: '2px dashed #eaeaea',
    marginBottom: 10,
    marginRight: 10,
    width: '50vw',
    height: 350,
    padding: 10,
    boxSizing: 'border-box',
  },
  thumb__inner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  },
  image__lg: {
    display: 'block',
    width: 350,
  },
  image__sm: {
    display: 'block',
    width: 125,
    marginLeft: 30,
  },
  help: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginTop: 10,
  },
};

export default withStyles(styles)(FileUpload);
