import React from 'react';
import size from 'lodash/size';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import SiteLink from './SiteLink';

const formatter = 'MMM Do, YYYY, hh:mm A';

const EventCard = ({ classes, ...event }) => {
  const {
    actions,
    id,
    image,
    ticketsTotalPaidCount,
    grandTotal,
    name,
    startDate,
    eventPackages: p,
    category,
  } = event;

  return (
    <Card className={classes.root} raised>
      <CardHeader
        title={
          <SiteLink style={{ color: '#000' }} to={`/events/${id}`}>
            {name}
          </SiteLink>
        }
        titleTypographyProps={{
          variant: 'body1',
          style: {
            fontWeight: 600,
            fontSize: 18,
          },
        }}
      />
      <CardMedia className={classes.media} image={image} title={name} />
      <CardContent>
        <Typography paragraph variant="body2" color="textSecondary" component="p">
          {format(startDate, formatter)}
        </Typography>
        <Typography style={{ marginBottom: 3 }} variant="body1" component="p">
          <strong>Category:</strong> {category.name}
        </Typography>
        <Typography style={{ marginBottom: 3 }} variant="body1" component="p">
          <strong>Total Packages Bought:</strong> {ticketsTotalPaidCount}
        </Typography>
        <Typography style={{ marginBottom: 3 }} variant="body1" component="p">
          <strong>Total Paid:</strong> ${parseFloat(grandTotal).toFixed(2)}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>{actions}</CardActions>
    </Card>
  );
};

const styles = theme => ({
  root: {
    width: 'calc(100% - 50px)',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

export default withStyles(styles, { withTheme: true })(EventCard);
