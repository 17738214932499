import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Provider as AlertProvider } from 'react-alert';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import AlertTemplate from 'react-alert-template-basic';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import configureStore from './redux/store';
import Theme from './containers/Theme';
import App from './containers/';
import './index.css';
import 'react-table/react-table.css';

const { store, persistor } = configureStore();
const history = createBrowserHistory();

// Alert configuration
const options = {
  position: 'bottom center',
  timeout: 5000,
  offset: '30px',
  transition: 'scale',
};

export { persistor, store };

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AlertProvider template={AlertTemplate} {...options}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Theme>
            <Router history={history}>
              <App />
            </Router>
          </Theme>
        </MuiPickersUtilsProvider>
      </AlertProvider>
    </PersistGate>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));
