import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ReactQuill from 'react-quill';
import cx from 'classnames';
import 'react-quill/dist/quill.snow.css';
import './quill.css';

const WYSIWYG = ({
  classes,
  label,
  input: { name, onChange, value },
  required,
  editorClassName,
}) => {
  return (
    <React.Fragment>
      {label && (
        <Typography style={{ color: 'rgba(0, 0, 0, 0.54)' }} variant="caption">
          {label}
          {required && ' *'}
        </Typography>
      )}
      <ReactQuill
        className={cx(classes.quill, editorClassName)}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ direction: 'rtl' }], // text direction
            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
            ['clean'], // remove formatting button
          ],
        }}
        defaultValue={value}
        onChange={(value, delta, source, editor) => {
          onChange(editor.getContents());
        }}
      />
    </React.Fragment>
  );
};

const styles = {
  quill: {
    paddingTop: 20,
    minHeight: 500,
  },
};

export default withStyles(styles)(WYSIWYG);
