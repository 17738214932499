import React from 'react';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';

export default ({
  input: { checked, value, name, onChange, ...restInput },
  meta,
  label,
  required,
}) => (
  <React.Fragment>
    <Typography style={{ color: 'rgba(0, 0, 0, 0.54)' }} variant="caption">
      {label}
      {required && ' *'}
    </Typography>
    <Switch
      name={name}
      inputProps={restInput}
      onChange={onChange}
      checked={!!checked}
      value={value}
    />
  </React.Fragment>
);
