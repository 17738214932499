import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import omit from 'lodash/omit';
import values from 'lodash/values';
import * as deepmerge from 'deepmerge';
import actions from './actions';

const createEntityState = ({ data = {}, isFetching = false, isDeleting = false }) => ({
  data,
  error: null,
  isFetching,
  isDeleting,
});

const initialState = {
  websites: createEntityState({ isFetching: true }),
  categories: createEntityState({}),
  events: createEntityState({}),
  discounts: createEntityState({}),
  locations: createEntityState({}),
  packages: createEntityState({}),
  hotels: createEntityState({}),
  registrations: createEntityState({}),
  customers: createEntityState({}),
  sponsors: createEntityState({}),
  testimonials: createEntityState({}),
  templates: createEntityState({}),
};

const arrayMerge = (destinationArray, sourceArray, options) => {
  const oldPackages = keyBy(destinationArray, 'id');
  const newPackages = keyBy(sourceArray, 'id');
  return values(deepmerge(oldPackages, newPackages, options));
};

const entitiesReducer = (state = initialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case actions.UPSERT_ENTITIES:
      return {
        ...state,
        [meta.entityType]: {
          data: deepmerge(
            get(state, [meta.entityType, 'data'], {}),
            get(payload, meta.entityType, {}),
            {
              arrayMerge,
            }
          ),
        },
      };

    case `${actions.FETCH_ENTITY}_START`: {
      const entity = get(state, [meta.entityType, 'data', meta.id], {});
      const newEntity = deepmerge(entity, {
        error: null,
        isFetching: true,
      });
      return {
        ...state,
        [meta.entityType]: {
          ...state[meta.entityType],
          data: {
            ...get(state, [meta.entityType, 'data'], {}),
            [meta.id]: newEntity,
          },
        },
      };
    }

    case `${actions.FETCH_ENTITY}_SUCCESS`: {
      const entity = get(state, [meta.entityType, 'data', meta.id], {});
      // Remove the error and fetching keys
      const newEntity = omit(deepmerge(entity, payload, { arrayMerge }), ['error', 'isFetching']);
      return {
        ...state,
        [meta.entityType]: {
          ...state[meta.entityType],
          data: {
            ...get(state, [meta.entityType, 'data'], {}),
            [meta.id]: newEntity,
          },
        },
      };
    }

    case `${actions.FETCH_ENTITY}_ERROR`: {
      const entity = get(state, [meta.entityType, 'data', meta.id], {});
      const newEntity = deepmerge(entity, {
        error: payload,
        isFetching: false,
      });
      return {
        ...state,
        [meta.entityType]: {
          ...state[meta.entityType],
          data: {
            ...get(state, [meta.entityType, 'data'], {}),
            [meta.id]: newEntity,
          },
        },
      };
    }

    case `${actions.FETCH_ENTITIES}_START`:
      return {
        ...state,
        [meta.entityType]: {
          ...state[meta.entityType],
          error: null,
          isFetching: true,
        },
      };

    case `${actions.FETCH_ENTITIES}_SUCCESS`:
      return {
        ...state,
        [meta.entityType]: {
          ...state[meta.entityType],
          data: keyBy(payload, 'id'),
          isFetching: false,
        },
      };

    case `${actions.FETCH_ENTITIES}_ERROR`:
      return {
        ...state,
        [meta.entityType]: {
          ...state[meta.entityType],
          error: payload,
          isFetching: false,
        },
      };

    case `${actions.DELETE_ENTITY}_START`: {
      const entity = get(state, [meta.entityType, 'data', meta.id], {});
      const newEntity = deepmerge(entity, {
        error: null,
        isDeleting: true,
      });
      return {
        ...state,
        [meta.entityType]: {
          ...state[meta.entityType],
          data: {
            ...get(state, [meta.entityType, 'data'], {}),
            [meta.id]: newEntity,
          },
        },
      };
    }

    case `${actions.DELETE_ENTITY}_SUCCESS`: {
      return {
        ...state,
        [meta.entityType]: {
          ...state[meta.entityType],
          // Remove entity from the store
          data: omit(get(state, [meta.entityType, 'data'], {}), [meta.id]),
        },
      };
    }

    case `${actions.DELETE_ENTITY}_ERROR`: {
      const entity = get(state, [meta.entityType, 'data', meta.id], {});
      const newEntity = deepmerge(entity, {
        error: payload,
        isDeleting: false,
      });
      return {
        ...state,
        [meta.entityType]: {
          ...state[meta.entityType],
          data: {
            ...get(state, [meta.entityType, 'data'], {}),
            [meta.id]: newEntity,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default entitiesReducer;
