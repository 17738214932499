import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import blue from '@material-ui/core/colors/blue';

const createTheme = ({ primary, secondary }) =>
  createMuiTheme({
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
      link: blue['500'],
    },
    typography: {
      useNextVariants: true,
    },
  });

const Theme = ({ children, config }) => (
  <React.Fragment>
    <CssBaseline />
    <MuiThemeProvider key={config} theme={createTheme(config)}>
      {children}
    </MuiThemeProvider>
  </React.Fragment>
);

Theme.propTypes = {
  children: PropTypes.element,
};

const mapStateToProps = ({
  app: { selectedWebsiteId },
  entities: {
    websites: { data },
  },
}) => {
  const website = get(data, selectedWebsiteId);
  return {
    config: {
      primary: get(website, 'primaryColor', '#000000'),
      secondary: get(website, 'secondaryColor', '#777777'),
    },
  };
};

export default connect(mapStateToProps)(Theme);
